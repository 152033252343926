;(function() {
	$(window).on('load', function() {

		// Focus our username field if empty, otherwise focus the password field
		if ($('#username').val().length) {
			$('#password').focus();
		} else {
			$('#username').focus();
		}

		// Define whether or not we're currently processing an event
		var processing = false;

		// Define whether or not we have loaded the CAPTCHA script
		var grecaptcha = null;

		// Add an enter keypress events
		$('#username, #password').on('keypress', function(e) {

			// If we pressed enter
			if ((e.keyCode ? e.keyCode : e.which) === 13) {
				$('#submit').trigger('click');
			}
		});

		// Create our login button
		$('#submit').button().on('click', function() {

			// Show the loading overlay
			$.loading(true);

			// Submit the form
			$.ajax({
				url: '/login/submit',
				method: 'POST',
				timeout: 30000,
				contentType: 'application/json',
				data: JSON.stringify({
					'username': $('#username').val(),
					'password': $('#password').val(),
					'captcha': (grecaptcha) ? grecaptcha.getResponse() : ''
				}),
				error: function () {
					$.loading(false);
					$('#error').text('We\'re sorry, an error occurred, please try again later.').show('fast')
				},
				success: function (r) {
					if (r.status === 0) {
						window.location.href = '/order/entry';
						return;
					}
					$.loading(false);

					// Output or message
					$('#error').text(r.message).show('fast');

					// If we don't need to show the captcha
					if (!r.locked) {
						processing = false;

					// If the captcha hasn't been loaded yet, load it
					} else if (!grecaptcha) {
						$.loading(true);

						// Load the script on the DOM
						// TODO: Figure out the captcha
						$.getScript('https://www.google.com/recaptcha/api.js?onload=onLoadCaptcha&render=explicit', function(data, textStatus, jqxhr) {

							// If we failed to load the script for any reason
							if (jqxhr.status !== 200) {
								$('#error').text('Failed to load CAPTCHA subsystem. Please try again later or contact technical support for more information.');
							}
						});

						// Show our captcha field, in case it's not visible
						$('#captcha').show('fast');
					} else {
						// Reload our captcha (get a new one)
						grecaptcha.reset();

						// Show our captcha field, in case it's not visible
						$('#captcha').show('fast');
					}
				}
			});
		});
	});
})(window.jQuery);
