;(function($) {
    "use strict";

    /**
     * Define our loading overlay/dom element, we look it up the first time we try to show/hide it
     */
    var loading = null;

    /**
     * Show/hide the loading overlay
     */
    $.loading = function(option) {
        if (loading === null) {
            loading = $('#loading').css('display', 'block');
            new Spinner({ color: '#FFF', shadow: true, scale: 1.2 }).spin(loading[0]);
        }

        if (option) {
            loading.appendTo('body');
        } else {
            loading.remove();
        }
    };
})(jQuery);
